import React from "react";
const Error404 = props => {
  return (
    <div className="gx-page-error-container">
      <div className="gx-page-error-content">
        <div className="gx-error-code gx-mb-4">404</div>
        <h2 className="gx-text-center">
          Error: No encontramos la empresa a la que esta intentando ingresar,
          por favor verifique el url ingresado<br/>
          <span><b>{props.url.toUpperCase()}</b></span>
        </h2>
      </div>
    </div>
  );
};
export default Error404;
