import React from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";
const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route exact path="/pendings" component={asyncComponent(() => import('./Pendings'))}/>
       <Switch>
        <Route path="/pendings/:TIPOCOMPROBANTE/:NUMEROCOMPROBANTE" component={ asyncComponent(() => import('./Pendings'))}/>
        <Route path="/changepassword" component={asyncComponent(() => import('./ChangePassword'))}/>
        <Route path="/automaticdebit" component={asyncComponent(() => import('./AutomaticDebit'))}/>
        <Route  component={asyncComponent(() => import('./Pendings'))}/>
      </Switch> 

    </Switch>
  </div>
);

export default App;