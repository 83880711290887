import IngProvider from "../lngProvider";
import store from '../appRedux/store';

const Traductor = (id) => {
    let lenguage = store().getState().settings.locale.locale;
    return IngProvider[lenguage].messages[id];
};

export default Traductor;

